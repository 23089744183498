.error__container {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  flex-direction: column;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.9);
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  transform: translateX(calc(-100%));
  transition: all 2s;
}

.error__container.setted {
  transform: translateX(0);
}

.accept__block {
  position: sticky;
  top: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error__text {
  color: orange;
  text-align: center;
}

.error__btn {
  font-size: 25px;
  margin-top: 50px;
  padding: 20px 40px;
  border-radius: 10px;
  border: 1px solid rgba(211, 211, 211, 0.377);
  background-color: transparent;
  color: rgba(211, 211, 211, 0.801);
}

.error__logo > i{
  font-size: 100px;
  color: rgb(182, 0, 0);
}
.error__logo {
  animation: glowError 2s ease-in-out infinite alternate;
  -webkit-animation: glowError 2s ease-in-out infinite alternate;
  -moz-animation: glowError 2s ease-in-out infinite alternate;
}

@keyframes glowError {
  from {
    text-shadow:  0 0 10px #fff, 0 0 20px #fff, 0 0 30px red, 0 0    40px red, 0 0 50px red, 0 0 60px red, 0 0 70px red;
    }
    to {
    text-shadow:  0 0 20px #fff, 0 0 30px red, 0 0 40px red, 0 0 50px red, 0 0 60px red, 0 0 70px red, 0 0 80px red;
    }
}

@media (max-width: 425px) {
  .accept__block {
   top: 15%;
  }
  .error__btn {
    width: 80%;
  }
}
@media (max-width: 375px) {
  .accept__block {
    top: 10%;
     }
}