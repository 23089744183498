.select {
  position: relative;
}

.categories__select {
  justify-content: center;
  margin-top: 40px;
  padding: 0px 4vw;
}

.select__button {
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  color: black;
  background: #FFFFFF;
  border: 2px solid orange;
  border-radius: 6px;
  box-shadow: 0 8px 15px #6D6D6D;
  height: 60px;

  padding: 13px 35px 13px 20px;

  font-weight: 700;
  font-size: 20px;
  line-height: 20px;

  cursor: pointer;
  white-space: nowrap;
}

.select__button::after {
  content: '';
  position: absolute;
  top: 55%;
  right: 15px;
  transform: translateY(-50%);

  width: 0;
  height: 0;
  border-width: 10.4px 6px 0 6px;
  border-color: lightgray transparent transparent transparent;
  border-style: solid;

  pointer-events: none;
}

.select__list {  
  display: none;

  position: absolute;
  left: 0;
  top: 52px;

  margin: 0;
  padding: 0;
  list-style-type: none;

  background-color: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(176, 198, 225, 0.6);
  overflow: hidden;

  border-radius: 6px;
  width: 100%;

  z-index: 1;
}
.select__list.select__list--visible {
  display: block;
}

.select__list-item {
  margin: 0;
  padding: 0;
  border: 1px solid lightgray;
  border-bottom: 0px;
  cursor: pointer;
}

.select__list-item:hover {
  border: 1px solid orange;
}

.select__list-item:first-child {
  border-radius: 6px 6px 0 0;
}
.select__list-item:last-child {
  border-radius: 0 0 6px 6px;
  border-bottom: 1px solid lightgray;
}
.select__list-item:last-child:hover {
  border-radius: 0 0 6px 6px;
  border-bottom: 1px solid orange;
}



.select__list-button {
  width: 100%;
  text-align: left;
  border: none;
  padding: 13px 20px;
  font-weight: 600;
}

.select__list-button-active {
  width: 100%;
  text-align: left;
  border: none;
  background-color: orange;
  padding: 13px 20px;
  font-weight: 600;
} 
.select__list-button:focus:not(:focus-visible),
.select__list-button-active:focus:not(:focus-visible) {
  box-shadow: none;
}


@media (min-width: 991px) {
  .categories__select {
    display: none;
  }
}

/* 
.select__list-button {
  margin: 0 2.5px;
  padding: 15px 24px;
  border-radius: 8px;
  border: 0;
  color: #6D6D6D;
  background-color: #F5F5F5;
  text-transform: uppercase;
  white-space: nowrap;
  transition: 0.3s;
  font-weight: bold;
}
.select__list-button:hover {
  color: #fff;
  background-color: orange;
  transition: 0.3s;
  box-shadow: 0 15px 20px #6D6D6D;
  color: white;
  transform: translateY(-7px);
}

.select__list-button:focus {
  margin: 0 2.5px;
  padding: 15px 24px;
  border-radius: 8px;
  border: 0;
  color: #6D6D6D;
  background-color: #F5F5F5;
  text-transform: uppercase;
  white-space: nowrap;
  transition: 0.3s;
  font-weight: bold;
  color: #fff;
  background-color: orange;
  transition: 0.3s;
  box-shadow: 0 15px 20px #6D6D6D;
  color: white;
  transform: translateY(-7px);
}

.select__list-button-active,
.select__list-button-active:hover,
.select__list-button-active:active,
.select__list-button-active:focus {
  margin: 0 2.5px;
  padding: 15px 24px;
  border-radius: 8px;
  border: 0;
  color: #6D6D6D;
  background-color: #F5F5F5;
  text-transform: uppercase;
  white-space: nowrap;
  transition: 0.3s;
  font-weight: bold;
  color: #fff;
  background-color: orange;
  transition: 0.3s;
  box-shadow: 0 15px 20px #6D6D6D;
  color: white;
  transform: translateY(-7px);
} */