.delivery__container {
  display: flex;
  justify-content: center;
}

.delivery__block {
  display: flex;
  justify-content: center;
}

.delivery__image-block {
  display: flex;
  justify-content: center;
}

.delivery__img {
  width: 80%;
}

.delivery__info-inner {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
}

.delivery__header {
  display: flex;
  margin-bottom: 0;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.delivery__text-block {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}

.delivery__info {
  padding-left: 1.5vw;
}

.delivery__icon {
  margin-bottom: 10px;
  margin-right: 20px;
}

.delivery__text-block p i {
  position: relative;
  padding-left: 20px;
}

.delivery__text-block p i::before {
  content: "";
  position:absolute;
  left: -15px;
  margin-left: 20px;
  top: 6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid orange;
}

/* Підкреслення для h1 */
.h-divider {

  margin: auto;
  margin-bottom: 0;
  margin-top: 0;
  width: 80%;
  position: relative;
}
.h-divider .divider__shadow {
  overflow: hidden;
  height: 30px;
}
.h-divider .divider__shadow:after {
  content: '';
  display: block;
  margin: -25px auto 0;
  width: 100%;
  height: 25px;
  border-radius: 125px/12px;
  box-shadow: 0 0 8px black;
}

@media (max-width: 1440px) {
  .delivery__image-block {
    align-items: center;
  }
  .delivery__img {
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 1190px) {
  .delivery__image-block {
    align-items: center;
  }
  .delivery__img {
    height: 80%;
    width: 110%;
  }
}

@media (max-width: 1080px) {
  .delivery__img {
    height: 70%;
    width: 120%;
  }
}

@media (max-width: 991px) {
  .delivery__img {
    height: 100%;
    width: 70%;
  }
}

@media (max-width: 840px) {
  .delivery__block {
    flex-direction: column;
    justify-content: center;
  }

  .delivery__image-block {
    width: 100%;
    justify-content: center;
  }

  .delivery__img {
    height: 100%;
    width: 70%;
  }

  .delivery__info-block {
    width: 100%;
  }

  .delivery__info-inner {
    padding: 0 2vw;
  }
}

@media (max-width: 575px) {
  .delivery__img {
    height: 100%;
    width: 100%;
  }
}

