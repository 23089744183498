.about__container {
  margin: 0;
  padding: 0;
}

.about__block {
  display: flex;
  margin: 0;
  margin-bottom: 50px;
  margin-top: 50px;
  padding: 0 12px;

}

.about__text-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about__header {
  display: flex;
  margin-bottom: 0;
  margin-top: 0;
  justify-content: center;
}

.about__list {
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  width: 70%;
}

.about__list-header {
  font-weight: bold;
  color: #ffb833;
  text-shadow: 1px 1px black;
}

/* .about__list-item {
  margin-bottom: 8vh;
} */

.about__list-item {
  position: relative;
  padding-left: 40px;
  margin: 15px 0;
}

.about__list-item::before {
  content: "";
  position:absolute;
  left: -15px;
  left: 20px;

  top: 8px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 3px solid orange;
}

/* Слайдер */

.about__images-block{
  display: flex;
  justify-content: center;
  padding: 0;
}

.carousel {
  width:100%;
  margin: 0 0vw;
  border-radius: 15px;
  margin-top: 20px;
  margin: 0 1vw;
  
}

.carousel-control-next,
.carousel-control-prev {
  opacity: 1;
}

.carousel-inner{
  border-radius: 15px;
}

.about__images {
  width: 100%;
  max-height: 600px;
  object-fit:cover;
}

/* Карта Гугл */

.google-map__container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;  
}

.google-map {
  border: 1px solid black;
  border-radius: 15px;
  width: 100%;
  height: 172px;
  object-fit:cover;
}

.google-map__text {
  font-weight: 900;
  padding-top: 15px;
  font-size: 1.5rem;
}

@media (max-width: 991px) {
  .about__block {
    flex-direction:column-reverse;
  }

  .about__header {
    margin-top: 25px;
  }
}



