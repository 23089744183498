.banner__container {
  font-family: 'Intro', sans-serif;
  height: 100vh;
  width: 100%;
  position: relative;
  background: no-repeat center center fixed;
  background-size: cover;
  background-color: black;
  padding: 0;
  margin: 0;
  cursor:grab;
  transition: 2s;
}
.banner__inner0, .banner__inner1, .banner__inner2 {
  position: absolute;
  color: white;
  top: 22%;
  left: 17%;
  text-transform: uppercase;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes banner__inner0 {
  0% {
     opacity: 0;
     -webkit-transform: translateY(-20px);
  }
  100% {
     opacity: 1;
     -webkit-transform: translateY(0);
  }
}
@keyframes banner__inner0 {
  0% {
     opacity: 0;
     transform: translateY(-20px);
  }
  100% {
     opacity: 1;
     transform: translateY(0);
  }
}
.banner__inner0 {
  -webkit-animation-name: banner__inner0;
  animation-name: banner__inner0;
}
@-webkit-keyframes banner__inner1 {
  0% {
     opacity: 0;
     -webkit-transform: translateY(-20px);
  }
  100% {
     opacity: 1;
     -webkit-transform: translateY(0);
  }
}
@keyframes banner__inner1 {
  0% {
     opacity: 0;
     transform: translateY(-20px);
  }
  100% {
     opacity: 1;
     transform: translateY(0);
  }
}
.banner__inner1 {
  -webkit-animation-name: banner__inner1;
  animation-name: banner__inner1;
}
@-webkit-keyframes banner__inner2 {
  0% {
     opacity: 0;
     -webkit-transform: translateY(-20px);
  }
  100% {
     opacity: 1;
     -webkit-transform: translateY(0);
  }
}
@keyframes banner__inner2 {
  0% {
     opacity: 0;
     transform: translateY(-20px);
  }
  100% {
     opacity: 1;
     transform: translateY(0);
  }
}
.banner__inner2 {
  -webkit-animation-name: banner__inner2;
  animation-name: banner__inner2;
}

.banner__text-big1 {
  font-size: 3rem;
  margin-bottom: 0;
  text-shadow: black 1px 1px;
}
.banner__text-small {
 font-size: 1.5rem;
 color: orange;
 text-shadow: black 1px 1px;
}
.banner__text-big2 {
 font-size: 3rem;
 margin-top: 30px;
 text-shadow: black 1px 1px;
 flex-wrap: wrap;
}

.carousel-switches {
  position: absolute;
  top: 93%;
  left: 45%;
  display: flex; 
  left: 50%; 
  transform: translate(-50%, -50%);
}

.switcher {
  width: 65px;
  height: 15px;
  background-color: rgba(255, 255, 255, 0.301);
  margin-right: 10px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 3px 2px 2px rgba(39, 39, 39, 0.637);
  z-index: 1;
}

.active-switcher {
  width: 65px;
  height: 15px;
  background-color: rgba(255, 255, 255, 0.637);
  margin-right: 10px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 6px 4px 4px rgba(39, 39, 39, 0.637);
  z-index: 1;
}

@media (min-width: 1500px) {
  .banner__inner0, .banner__inner1, .banner__inner2 {
    width: 40vw;
    top: 22%;
    left: 17%;
  }
}
@media (max-width: 1500px) {
  .banner__inner0, .banner__inner1, .banner__inner2 {
    width: 50vw;
    top: 18%;
    left: 15%;
  }
}
@media (max-width: 1200px) {
  .banner__text-big1 {
    font-size: 2.5rem;
  }
  .banner__text-small {
   font-size: 1.2rem;
  }
  .banner__text-big2 {
   font-size: 2.5rem;
  }
}
@media (max-width: 900px) {
  .banner__inner0, .banner__inner1, .banner__inner2 {
    width: 60vw;
  }
}
@media (max-width: 750px) {
  .banner__inner0, .banner__inner1, .banner__inner2 {
    width: 70vw;
  }
}
@media (max-width: 650px) {
  .banner__inner0,.banner__inner1,.banner__inner2 {
    width: 80vw;
  }
}
@media (max-width: 550px) {
  .banner__container {
    background:  0 0/cover no-repeat;
    min-height: 700px;
    height: 700px;
  }
  .banner__inner0,.banner__inner1,.banner__inner2 {
    top: 18%;
    left: 12%;
  }
  .banner__text-big1 {
    font-size: 2rem;
  }
  .banner__text-small {
   font-size: 1rem;
  }
  .banner__text-big2 {
   font-size: 2rem;
  }
}
@media (max-width: 400px) {
  .banner__inner0,.banner__inner1,.banner__inner2 {
    top: 18%;
    left: 10%;
  }
  .banner__text-big1 {
    font-size: 2rem;
  }
  .banner__text-small {
   font-size: 1rem;
  }
  .banner__text-big2 {
   font-size: 2rem;
  }
}
@media (max-width: 360px) {
  .banner__text-big2 {
   font-size: 1.8rem;
  }
}
@media (max-width: 350px) {
  .banner__inner0,.banner__inner1,.banner__inner2 {
    top: 18%;
    left: 10%;
  }
  .banner__text-big1 {
    font-size: 1.8rem;
  }
  .banner__text-small {
   font-size: 1rem;
  }
  .banner__text-big2 {
   font-size: 1.8rem;
   margin-top: 20px;
  }
}