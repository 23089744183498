.categories__menu {
  display: flex;
  justify-content: center;
}

.categories__list {
  display: flex;
  justify-content: center;
  background-color: #F5F5F5;
  margin-top: 50px;
  flex-wrap: wrap;
  width: 70%;
  border-radius: 8px;
}

.categories__list-button {
  margin: 0 2.5px;
  padding: 15px 24px;
  border-radius: 8px;
  border: 0;
  color: #6D6D6D;
  background-color: #F5F5F5;
  text-transform: uppercase;
  white-space: nowrap;
  transition: 0.3s;
  font-weight: bold;
}
.categories__list-button:hover {
  color: #fff;
  background-color: orange;
  transition: 0.3s;
  box-shadow: 0 15px 20px #6D6D6D;
  color: white;
  transform: translateY(-7px);
}

.categories__list-button:focus {
  margin: 0 2.5px;
  padding: 15px 24px;
  border-radius: 8px;
  border: 0;
  color: #6D6D6D;
  background-color: #F5F5F5;
  text-transform: uppercase;
  white-space: nowrap;
  transition: 0.3s;
  font-weight: bold;
  color: #fff;
  background-color: orange;
  transition: 0.3s;
  box-shadow: 0 15px 20px #6D6D6D;
  color: white;
  transform: translateY(-7px);
}

.categories__list-button-active,
.categories__list-button-active:hover,
.categories__list-button-active:active,
.categories__list-button-active:focus {
  margin: 0 2.5px;
  padding: 15px 24px;
  border-radius: 8px;
  border: 0;
  color: #6D6D6D;
  background-color: #F5F5F5;
  text-transform: uppercase;
  white-space: nowrap;
  transition: 0.3s;
  font-weight: bold;
  color: #fff;
  background-color: orange;
  transition: 0.3s;
  box-shadow: 0 15px 20px #6D6D6D;
  color: white;
  transform: translateY(-7px);
}

@media (max-width: 991px) {
  .categories__menu {
    display: none;
  }
}
