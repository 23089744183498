/* orderCart__inner */
.orderCart__inner {
  width: 50%;
  height: 60vh;
  min-height: auto;
  background-color: white;
  background-repeat: repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}
.orderCart__header {
  padding: 10px;
  font-size: 2rem;
  color: black;
  width: 100%;
  text-align: center;
}
.orderCart__product {
  color: black;
}
.orderCart__products-list {
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.orderCart__empty {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.orderCart__empty span {
  color: #3D3D3D;
  font-size: 1.5rem;
}
.orderCart__item {
  margin: 10px 10px;
  padding: 10px 10px;
  display: flex;
  width: auto;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 15px;
}
.orderCartItem__img-container {
  width: 95px;
  height: 85px;
}
.orderCartItem__img {
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
  object-fit: fill;
}
.orderCartItem__info {
  display: flex;
  padding-left: 20px;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
  text-align: center;
}
.orderCartItem__name {
  width: 20%;
}
.orderCartItem__name h5{
  color: black;
  font-size: 1.3rem;
  width: 100%;
  margin: 0;
}
.orderCartItem__weight  {
  width: 10%;
  white-space: nowrap;
}
.orderCartItem__weight h5{
  color: black;
  font-weight: 200;
  font-size: 1rem;
  padding-top: 3px;
  margin: 0;
}
.orderCartItem__price h5{
  color: black;
  font-size: 1.2rem;
  margin: 0;
}
.orderCartItem__buttons {
  display: flex;
  align-items: center;
}
.orderCartItem__button {
  border: none;
  font-weight: bold;
  font-size: 1.25rem;
  text-transform: uppercase;
  white-space: nowrap;
  color: #3D3D3D;
  background-color: #F5F5F5;
  transition: 0.2s;
  padding: 10px 15px;
  margin: 0 10px;
  border-radius: 10px;
}
.orderCartItem__button:hover{
  color: #fff;
  background-color: #ffb833;
  transition: 0.2s;
  box-shadow: 0 10px 15px #6D6D6D;
  color: white;
}
.orderCartItem__button-amount {
  color: black;
}
.orderCartItem__delete-button {
  border: none;
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
  white-space: nowrap;
  color: orangered;
  background-color: #F5F5F5;
  transition: 0.2s;
  padding: 10px 15px;
  margin: 0 10px;
  border-radius: 10px;
}
.orderCartItem__delete-button:hover {
  background-color: orangered;
  color: #3D3D3D;
  box-shadow: 0 10px 15px #6D6D6D;
}

@media  (min-width: 1200px) and (max-width:1400px) {
  .cart__item {
    display: flex;
    padding: 10px 10px;
    /* flex-wrap: wrap; */
  }

  .orderCartItem__img-container {
    flex: 0 0 85px;
  }
  .orderCartItem__info {
    flex: 1 1 auto;
    display: flex;
    justify-content:space-between;
    flex-wrap: wrap;
  }

  .orderCartItem__name {
    width: 100%;
    text-align: left;
    white-space: nowrap;
    flex: 0 1 100%;
    text-align: start;
  }
  .orderCartItem__weight{
    white-space: nowrap;
    flex: 0 1 100%;
    text-align: left;
    padding-bottom: 10px;
  }
  .orderCartItem__price{
    flex: 0 1 auto;
    white-space: nowrap;
  }
  .orderCartItem__buttons {
    white-space: nowrap;
    flex: 0 1 auto;
  }
  .orderCartItem__delete {
    flex: 0 1 auto;
  }

  .orderCartItem__delete-button {
    margin: 0 0 0 5px;
  }

  .orderCartItem__name h5,  .orderCartItem__weight h5{
    margin-left: 10px;
  }

  .orderCart__footer {
    flex-direction: column;
  }
  .orderCart__footer-block {
    padding: 5px 0;
  }
  .orderCart__footer-accept, .cart__footer-accept-disabled {
    margin-bottom: 10px;
  }
}  

@media  (max-width: 1200px) {
  .orderCart__inner {
    border-left: none;
    width: 100%;
    height: 100%;
  }

  
  .orderCart__item {
    margin: 10px 100px;
  }
}

@media  (max-width: 850px) {
  .orderCart__item {
    margin: 10px 10px;
  }
}

@media (min-width: 700px) {
  .orderCartItem__info {
    display: flex;
    padding-left: 20px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    text-align: center;
  }
}

@media (max-width: 700px) {

  .cart__item {
    display: flex;
    padding: 10px 10px;
    /* flex-wrap: wrap; */
  }

  .orderCartItem__img-container {
    flex: 0 0 85px;
  }
  .orderCartItem__info {
    flex: 1 1 auto;
    display: flex;
    justify-content:space-between;
    flex-wrap: wrap;
  }

  .orderCartItem__name {
    width: 100%;
    text-align: left;
    white-space: nowrap;
    flex: 0 1 100%;
    text-align: start;
  }
  .orderCartItem__weight{
    white-space: nowrap;
    flex: 0 1 100%;
    text-align: left;
    padding-bottom: 10px;
  }
  .orderCartItem__price{
    flex: 0 1 auto;
    white-space: nowrap;
  }
  .orderCartItem__buttons {
    white-space: nowrap;
    flex: 0 1 auto;
  }
  .orderCartItem__delete {
    flex: 0 1 auto;
  }

  .orderCartItem__delete-button {
    margin: 0 0 0 5px;
  }

  .orderCartItem__name h5,  .orderCartItem__weight h5{
    margin-left: 10px;
  }

  .orderCart__footer {
    flex-direction: column;
  }
  .orderCart__footer-block {
    padding: 5px 0;
  }
  .orderCart__footer-accept, .cart__footer-accept-disabled {
    margin-bottom: 10px;
  }
}

@media (max-width: 425px) {
  .orderCartItem__name h5{
    font-size: 1.3rem;
    white-space: normal;
  }
  .orderCartItem__name {
    font-size: 1.3rem;
    white-space: wrap;
  }
  .orderCartItem__info {
    padding: 1px 1px;
  }
  .orderCartItem__price h5{
    font-size: 1rem;
  }
}

@media (max-width: 375px) {
  .forms__title1, .forms__title2 {
    font-size: 1.5rem;
  }
  .orderCartItem__name {
    order: 1;
  }
  .orderCartItem__weight{
    order: 2;
  }
  .orderCartItem__price{
    order: 3;
    flex: 0 1 100%;
    text-align: left;
    margin-left: 10px;
    padding-bottom: 10px;
  }
  .orderCartItem__buttons {
    order: 4;
  }
  .orderCartItem__delete {
    order: 5;
  }
  .orderCartItem__price h5 {
    font-size: 1.2rem;
  }
}