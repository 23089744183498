.loader {
  /* display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 400px */
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  overflow: auto;
}

.loader__inner {
font-size: 80px;
}

.loader__container {    
  width: 112px;
  height: 112px;
  /* width: 250px;
  height: 250px; */
  position: absolute;
}
.box11, .box22, .box33 {
  border: 16px solid orange;
  box-sizing: border-box;
  position: absolute;
  display: block;
}
.box11{
 width: 112px;
 height: 48px;
 margin-top: 64px;
 margin-left: 0px;
 animation: anime1 4s 0s forwards ease-in-out infinite;
}
.box22 {       
 width: 48px;
 height: 48px;
 margin-top: 0px;
 margin-left: 0px;
 animation: anime2 4s 0s forwards ease-in-out infinite;
}
.box33{ 
  width: 48px;
  height: 48px;
  margin-top: 0px;
  margin-left: 64px;
  animation: anime3 4s 0s forwards ease-in-out infinite;
}
       
       
@keyframes anime1 {
  0% {    width: 112px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;}
  12.5%
{    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left :0px;}
  25%
{    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;}
  37.5%
{    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;}
  50%
{    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left :0px;}
  62.5%
{    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;}
  75%
{    width: 48px;
    height: 112px;
    margin-top: 0px;
    margin-left: 0px;}
  87.5%{    
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;}
  100%
{    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;}
  }
    
    
@keyframes anime2 {
  0%
{    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;}
  12.5%
{    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;}
  25%
{    width :48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;}
  37.5%
{    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;}
  50%
{    width: 112px;
    height: 48px;
    margin-top: 0px;
    margin-left :0px;}
  62.5%
{    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;}
  75%
{    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left :64px;}
  87.5%
{    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;}
  100%
{    width: 48px;
    height: 48px;
    margin-top :0px;
    margin-left: 64px;}}
    
@keyframes anime3{
  0%
{    width: 48px;
    height :48px;
    margin-top: 0px;
    margin-left :64px;}
  12.5%
{    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;}
  25%
{    width: 48px;
    height: 112px;
    margin-top: 0px;
    margin-left: 64px;}
  37.5%
{    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left :64px;}
  50%
{    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;}
  62.5%
{    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left :64px;}
  75%
{    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;}
  87.5%
{    width: 48px;
    height: 48px;
    margin-top :64px;
    margin-left :64px;}
  100%
{    width :112px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;}
  }