.loading__container {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  flex-direction: column;
  z-index: 20;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  transform: translateX(calc(-100%));
  align-items: center;
}

.loading__container.setted {
  overflow: visible;
  transform: translateX(0);
}

.loading__block {
  position: sticky;
  display: flex;
  top: 45%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}