.fixed-top {
  background-color: #21211f;
  background-image: url('../../assets/images/footer-wall.jpg');
  background-size: contain;
  width: 100%;
  z-index: 10;
  position: fixed;
  left: 0;
  top: 0;
}
.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  margin: 0 auto;
  padding: 0 20px;
}
.header__menu {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.nav-link:hover {
  color: orange;
} 
.nav-link:focus {
  color: orange;
} 
.nav-link:active {
  color: orange;
} 

@media (min-width: 950px) {
  .menu__body {
  display: flex;
  width: 60%;
  justify-content: flex-end;
  }
  .menu__list {
  display: flex;
  justify-content: flex-end;
  width: 70%;
  }
  .menu__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  }
  .nav-link {
  text-decoration: none;
  color: rgba(211, 211, 211, 0.589);
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 0 15px;
  }

  .menu__icon {
  display: none;
  }
}

.menu__list, .menu__info {
  display: flex;
  align-items: center;
}
.menu__list > li, .menu__info > li {
  padding: 10px 0;
  margin: 0px auto;
}
.logo__img {
  width: 150px;
}
.telephone {
  color: lightgray;
  text-decoration: none;
  line-height: 26px;
  font-size: 1.2rem;
  font-weight: 700;
  white-space: nowrap;
  margin-bottom: 18px;
}
.telephone:focus {
  color: orange;
}
.bi-telephone {
  color: orange;
}
.telephone:hover {
  transition: 0.3s ;
  color: orange
}

@media (max-width: 1100px) {
  .menu__body {
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 1024px) {
  .menu__body {
    display: flex;
    justify-content: flex-end;
    padding-left: 10px;
  }
  .menu__info {
    justify-content: space-between;
    width: 45%;
  }
  .bi-basket{
    padding-left: 15px;
  }
  .phone__number, .navCart__text, .nav-link {
    font-size: 1.1rem;
  }
}

@media (max-width: 950px) {
  .menu__icon {
    order: 3;
    z-index: 5;
    display: block;
    position: relative;
    width: 30px;
    height: 18px;
    cursor: pointer;
    right: 20px;
  }
  .menu__icon span,
  .menu__icon::before,
  .menu__icon::after {
    background-color: white;
    left: 0;
    position: absolute;
    height: 2px;
    width: 100%;
    transition: all 0.3s ease 0s;
  }
  .menu__icon::before,
  .menu__icon::after {
    content: '';
  }
  .menu__icon::before {
    top: 0;
  }
  .menu__icon::after {
    bottom: 0;
  }
  .menu__icon span {
    top: 50%;
    transform: scale(1) translate(0px, -50%);
  }
  .menu__icon._active span{
    transform: scale(0) translate(0px, -50%);
  }
  .menu__icon._active::before {
    top: 50%;
    transform: rotate(-45deg) translate(0px, -50%);
  }
  .menu__icon._active::after {
    bottom: 50%;
    transform: rotate(45deg) translate(0px, 50%);
  }



  .menu__body {
    order: 2;
    position: fixed;
    left: -100%;
    top: 100px;
    width: 100%;
    height: auto;
    background-color: rgba(0,0,0,0.7);
    padding: 10px 30px;
    transition: left 0.3s ease 0s;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 3;
  }

  .menu__body._active {
    left: 0;
  }

  .menu__list{
    flex-direction: column;
    align-items:baseline;
    width: 100%;  
  }
  .menu__list > li {
    margin: 0px 0px 30px 0px;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menu__list > li:last-child {
    margin: 0px 0px 10px 0px;
  }
  .nav-link {
    padding: 0;
    font-size: 32px;
    text-decoration: none;
    color: #fff;
  }
  .menu__info {
    order: 1;
    width: 90%;
    justify-content: flex-end;
  }
  .menu__info > li {
    margin: 0;
  }

}

@media (max-width: 600px) {
  .header__container {
    padding: 0px 0px 0px 20px;
  }
  .telephone {
    margin-right: 0px;
    padding: 5px 5px;
  }
  .bi-telephone {
    font-size: 1.5rem;
  }
  .phone__number {
    display: none;
  }
  .menu__icon {
    margin-left: 40px;
  }
}

@media (max-width: 425px) {
  .header__container {
    height: 80px;
    padding: 0px 0px 0px 10px;
  }
  .menu__body {
    top: 80px;
  }
  .logo__img {
    width: 100px;

  }
  .telephone {
    margin-right: 0px;
  }
  .bi-telephone {
    font-size: 1.2rem;
  }
  .menu__icon {
    margin-left: 15px;
  }
}

@media (max-width: 375px) {
  .telephone {
    margin-right: -15px;
  }
}
@media (max-width: 350px) {
  .telephone {
    margin-right: -20px;
  }
  .menu__icon {
    right: 12px;
  }
}
@media (max-width: 335px) {
  .telephone {
    margin-left: 10px;
  }
  .menu__icon {
    right: 15px;
  }
}
@media (max-width: 330px) {
  .menu__icon {
    right: 18px;
  }
}
@media (max-width: 325px) {
  .menu__icon {
    right: 26px;
  }
}






