.review__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
  background-image: url('../../../assets/images/footer-wall.jpg');
  background-attachment:fixed;
  width: 100%;
  padding-bottom: 20px;
}

.review__container > h2 {
  margin-bottom: 20px;
  color: #ffb833;
  padding-top: 35px;
  font-size: 36px;
}

.review__block > span {
  display: block;
  color: #ffffff;
  margin-bottom: 10px;
  margin-top: 20px;
}

.review__block h5 {
  margin-top: 10px;
  color: #ffb833;
  font-style: italic;
}


.review__block > span {
  position: relative;
  display: block;
  padding-bottom: 25px;
  font-style: italic;
  font-weight: 500;
}

.review__block > span:before, .review__block > span:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100px;
  height: 1px;
  background: #3a3a3a;
}
.review__block > span:after {
  bottom: -1px;
  width: 2px;
  height: 3px;
  background: #ffb833c2;
}