@font-face {
	font-family: 'Intro';
  src: local('Intro'), url('./app/assets/fonts/Intro.woff2') format('woff2');
	src: local('Intro'), url('./app/assets/fonts/Intro.woff') format('woff');
  font-weight: 500;
	font-style: normal;
}

@font-face {
  font-family: 'myicons';
  src:  url('../src/app/assets/fonts/myicons.eot?h75jhk');
  src:  url('../src/app/assets/fonts/myicons.eot?h75jhk#iefix') format('embedded-opentype'),
    url('../src/app/assets/fonts/myicons.ttf?h75jhk') format('truetype'),
    url('../src/app/assets/fonts/myicons.woff?h75jhk') format('woff'),
    url('../src/app/assets/fonts/myicons.svg?h75jhk#myicons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="_icon-"], [class*=" _icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'myicons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._icon-largeportion:before {
  content: "\e900";
}
._icon-happyface:before {
  content: "\e901";
}
._icon-fresh:before {
  content: "\e902";
}
._icon-kitchen:before {
  content: "\e903";
}

#root {
  height: 100%;
}

.banner__container {
  font-family: 'Intro', sans-serif;
}

html, body {
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 100%;
  height: 100%;
  width: 100%;
}

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  position: relative;
  transition: all 2s;
}

.main {
  flex: 1 1 auto;
}

.footer__block {
  flex-shrink: 0;
}