.benefits__block{
  background-image: url('../../../assets/images/footer-wall.jpg');
  background-attachment:fixed;
  height: 100%;
  color: #fff;
  margin: 20px 0;
}

.benefits__item {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 70px 0 40px 0;
}

.benefits__item h1 {
  text-align: center;
  padding-top: 20px;
}

.benefits__item span {
  padding: 0 30px;
  text-align: center;
}

._icon-kitchen {
  color: orange;
  font-size: 60px;
  border: 1px solid orange;
  border-radius: 25px;
  padding: 30px 26.26px;
  line-height: 1;
}

._icon-happyface,
._icon-fresh,
._icon-largeportion {
  color: orange;
  font-size: 60px;
  
  border: 1px solid orange;
  border-radius: 25px;
  padding: 30px 30px;
}



@media (max-width: 991px) {
  .benefits__block {
    margin: 25px 0;
  }
}

@media (max-width: 555px) {
  .benefits__block {
    display: none;
  }
}