/* orderForms__inner */
.orderForms__inner {
  width: 50%;
  height: auto;
  display: flex;
  justify-content: center;
}

.forms__title1, .forms__title2, .forms__title3{
  margin-bottom: 32px;
}

.forms__title1::before {
  content: '1.';
  font-size: 1.5rem;
  border: 1px solid #ffb833;
  padding: 3px 11px 3px 15px;
  background-color: #ffb833;
  border-radius: 15px;
}
.forms__title2::before {
  content: '2.';
  font-size: 1.5rem;
  border: 1px solid #ffb833;
  padding: 3px 11px 3px 15px;
  background-color: #ffb833;
  border-radius: 15px;
}
.forms__title3::before  {
  content: '3.';
  font-size: 1.5rem;
  border: 1px solid #ffb833;
  padding: 3px 11px 3px 15px;
  background-color: #ffb833;
  border-radius: 15px;
}
.forms__title4::before  {
  content: '+';
  font-size: 1.5rem;
  border: 1px solid #ffb833;
  padding: 3px 13px 3px 13px;
  background-color: #ffb833;
  border-radius: 15px;
}

.order__form {
  width: 100%;
  justify-content: center;
  border-radius: 10px;
  margin: 10px 30px;
  padding: 0 20px;
  letter-spacing: 1px;
}

.forms__group {
  position: relative;
  margin-bottom: 32px;
}

/* Розподіл форм по сторінці */
.forms__second-group {
  display: flex;
  flex-wrap: wrap;
}
.elem-1 {
  flex: 0 1 100%;
}
.elem-2, .elem-3, .elem-4, .elem-5 {
  flex: 0 1 50%;
  width: 100%;
}
/* */

.forms__label {
  position: absolute;
  top: 0;
  left: 0;
  color: #6e6e6e;
  transition: 0.3s;
  z-index: 0;
}

.forms__input {
  width: 100%;
  padding: 0 0 10px 0;
  border: none;
  border-bottom: 1px solid #e0e0e0;
  background-color: transparent;
  outline: none;
  transition: 0.3s;
  z-index: 1;
  position: relative;
}

.forms__input:focus {
  border-bottom: 3px solid #ffb833;
}

.forms__input:focus ~ .forms__label,
.forms__input:not(:placeholder-shown) ~ .forms__label {
  top: -18px;
  font-size: 12px;
}

.checkbox__container {
  display: flex;
}

.checkbox {
  display: flex;
  margin-bottom: 30px;

  font-size: 16px;
  line-height: 20px;
  padding: 0px 0px 0px 70px;
  position: relative;
  cursor: pointer;

  transition: all 0.3s ease 0s;
}
.checkbox.box-active {
  color: #000;
}
.checkbox.box-active::before{
  background-color: #ffb833;
}
.checkbox.box-active::after {
background-color: #ffb833;
/* left: 25px; */
}


.checkbox::before{
  content: '';
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #bfc0c4;
  position: absolute;
  left: 0px;
  top: -10px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
.checkbox::after{
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(78, 78, 78, 0.171);
  left: 5px;
  top: -5px;
  background-color: #bfc0c4;
  border-radius: 50%;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}


.checkbox input {
  display: none;
}
input.checkbox__input {  
  margin-right: 5px;
  width: 20px;
  background-color: #ffb833;
}
.checkbox__input {
  background-color: #ffb833;
}
.checkbox__label-one, .checkbox__label-two,.checkbox__label-three {
  font-size: 24px;
  margin-left: -10px;
}
.checkbox__label-one, .checkbox__label-two {
  margin-right: 15px;
}

.errTxt {
  color: red;
  font-style: italic;
}

@media (max-width: 1200px) {
  .orderForms__inner {
    width: 100%;
  }
}

@media (max-width: 700px){
  .checkbox__container {
    flex-direction: column;
  }
}

@media (max-width: 425px) {
  .elem-1 {
    flex: 0 1 100%;
  }
  .elem-2, .elem-3, .elem-4, .elem-5 {
    flex: 0 1 100%;
  }
}
