.pagination__container {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  margin-bottom: 3vh;
}

.pagination {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.page-item {
  margin: 7px 1vh;
  
}
.page-item,
.page-item:hover,
.page-item:active,
.page-item:focus {
  box-shadow: none;
}

.page-link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #3d3d3d;
  border: 1px solid #ffb833;
  border-radius: 30%;
  padding: 15px 20px;
}

.page-link:hover {
  color: #fff;
  background-color: #ffb833;
}

.active-page,
.active-page:hover,
.active-page:active,
.active-page:focus {
  background-color: #ffb833;
  color: #fff;
  box-shadow: none;
  
}

.anchor {
  text-decoration: none;
}