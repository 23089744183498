.accepted__container {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  flex-direction: column;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.9);
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  transform: translateX(calc(-100%));
  transition: all 2s;
}

.accepted__container.setted {
  transform: translateX(0);
}

.accept__block {
  position: sticky;
  top: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.accepted__text {
  color: orange;
  text-align: center;
}

.accepted__btn {
  font-size: 25px;
  margin-top: 50px;
  padding: 20px 40px;
  border-radius: 10px;
  border: 1px solid rgba(211, 211, 211, 0.377);
  background-color: transparent;
  color: rgba(211, 211, 211, 0.801);
}

.accepted__logo > i{
  font-size: 100px;
  color: rgb(34, 143, 34);
}
.accepted__logo {
  animation: glow 2s ease-in-out infinite alternate;
  -webkit-animation: glow 2s ease-in-out infinite alternate;
  -moz-animation: glow 2s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow:  0 0 10px #fff, 0 0 20px #fff, 0 0 30px green, 0 0    40px green, 0 0 50px green, 0 0 60px green, 0 0 70px green;
    }
    to {
    text-shadow:  0 0 20px #fff, 0 0 30px green, 0 0 40px green, 0 0 50px green, 0 0 60px green, 0 0 70px green, 0 0 80px green;
    }
}

@media (max-width: 425px) {
  .accept__block {
   top: 15%;
  }
}
@media (max-width: 375px) {
  .accept__block {
    top: 10%;
     }
}