/* Блок ціни/підтвердження притискаю вниз */
/* .order__container {
  height: 100%;
  display: flex;
  flex: 1 0 auto;
}


.orderCart__footer-container {
  flex-shrink: 0;
} */
/* *** */



.order__container{
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  margin-top: 20px;
  padding: 0;

  /* height: 100%;
  display: flex;
  flex: 1 0 auto; */
}
.order__accept {
  display: block;
}

.orderCart__footer-container {
  width: 100%;
  padding: 0;
  margin: 0;

  /* flex-shrink: 0; */
}
.orderCart__footer {
  min-height: 150px;
  background-image: url('../../../assets/images/footer-wall.jpg');
  background-size: cover;
  width: 100%;
  margin-left: 0;
  display: flex;
}
.orderCart__footer-block {
 padding: 0;
 margin: 0;
 width: 100%;
 display: flex;
 justify-content: center;
 align-items: center;
 text-align: center;
}
.orderCart-link {
  width: 80%;
  
}

.orderCart__footer-accept {
  position: relative;
  background-color: #ffb833;
  height: 60px;
  width: 100%;
  font-weight: 700;
  border-radius: 5px;
  transition: all 0.3s;
  font-size: 21px;
}

.orderCart__footer-accept > span{
  position: absolute;
  color: #ff0000c9;
  font-style: italic;
  font-weight: 400;
  /* left: 50%; */
  right: 0;
  top: 70px;
  font-size: 16px;
  width: 100%;
} 
.orderCart__footer-accept:hover {
  background-color:transparent;
  transition: all 0.2s;
  border: 1px solid #ffb833;
  color: #ffb833;
  box-shadow: 0 0 10px 0 orange inset, 0 0 10px 4px orange;
}
.orderCart__footer-accept-disabled {
  background-color: #ffb833;
  height: 60px;
  width: 75%;
  font-weight: 700;
  border-radius: 5px;
}
.big-text {
  color: #ffb833;
  font-size: 2.5rem;
}
.lil-text {
  padding-right: 15px;
  padding-left: 10px;
  color: white;
  font-size: 1.5rem;
}
.order-link {
  width: 75%;
}

@media (max-width: 1200px) {
  .order__container{
    flex-wrap: wrap;
    flex-direction: column;
  }
  .orderForms__inner {
    width: 100%;
  }
}

@media (max-width: 650px) {
  .orderCart__footer {
    flex-direction: column;

  }
  .orderCart__footer-block {
    padding: 5px 0;
    margin-top: 20px;
  }
  .orderCart-link {
    padding: 0 0 30px 0;
  }
  .orderCart__footer-accept {
    font-weight: 700;
    font-size: 18px;
  }
}
