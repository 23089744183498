
/* Поява cart__sidebar і затемнення + блюр */
.eclipce {
  opacity: 0;
}
.eclipce.active {
  width: 100%;
  height: 100%;
  top: 100px;
  left: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(2px);
  opacity: 1;
  transition: all 0.3s;
}
.cart__sidebar {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 100px;
  left:0;
  transform: translateX(calc(-100% - 700px));
  transition: all 0.5s;
  z-index: 2;
}
.cart__sidebar.active {
  transform: translateX(0);
  transition: all 0.5s;
}
.cart__inner {
  width: 700px;
  height: calc(100% - 100px);
  min-height: calc(100% - 100px);
  background-color: white;
  background-repeat: repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cart__header {
  padding: 10px;
  font-size: 2rem;
  color: black;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  text-align: center;
  position: relative;
}
.cart__close {
  position: absolute;
  right: 35px;
  top: 12px;
}
.bi-x{
  color: #bdbcbc;
}
.cart__product {
  color: black;
}

@media (max-width: 700px) {
  .cart__inner {
    width: 100%;
  }
}

/* /// */

/* Сам sidebar */

.cart__products-list {
  width: 100%;
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 0 10px;
}
.cart__empty {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.cart__empty span {
  color: #3D3D3D;
  font-size: 1.5rem;
}
.cart__item {
  margin: 10px 0;
  padding: 10px 15px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}
.cartItem__img-container {
  width: 95px;
  height: 85px;
}
.cartItem__img {
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
  object-fit: fill;
}

.cartItem__info {
  display: flex;
  padding-left: 20px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.cartItem__name {
  width: 20%;
}
.cartItem__name h5{
  color: black;
  font-size: 1.5rem;
  width: 100%;
  margin: 0;
}

.cartItem__weight h5{
  color: black;
  font-weight: 200;
  font-size: 1rem;
  margin: 0;
}
.cartItem__price h5{
  color: black;
  font-size: 1.5rem;
  margin: 0;
}
.cartItem__buttons {
  display: flex;
  align-items: center;
}
.cartItem__button {
  border: none;
  font-weight: bold;
  font-size: 1.25rem;
  text-transform: uppercase;
  white-space: nowrap;
  color: #3D3D3D;
  background-color: #F5F5F5;
  transition: 0.1s;
  padding: 10px 15px;
  margin: 0 10px;
  border-radius: 10px;
}
.cartItem__button:hover{
  color: #fff;
  background-color: #ffb833;
  transition: 0.1s;
  box-shadow: 0 5px 7px #6D6D6D;
  color: white;
}
.cartItem__button-amount {
  color: black;
}
.cartItem__delete-button {
  border: none;
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
  white-space: nowrap;
  color: orangered;
  background-color: #F5F5F5;
  transition: 0.2s;
  padding: 10px 15px;
  margin: 0 10px;
  border-radius: 10px;
}
.cartItem__delete-button:hover {
  background-color: orangered;
  color: #3D3D3D;
  box-shadow: 0 10px 15px #6D6D6D;
}


.cart__footer-container {
  width: 100%;
  padding: 0;
  margin: 0;
}
.cart__footer {
  min-height: 150px;
  background-image: url('../../../assets/images/footer-wall.jpg');
  background-size: cover;
  width: 100%;
  margin-left: 0;
  display: flex;
}
.cart__footer-block {
 padding: 0;
 margin: 0;
 width: 100%;
 display: flex;
 justify-content: center;
 align-items: center;
 text-align: center;
}
.cart__footer-accept {
  background-color: #ffb833;
  height: 60px;
  width: 100%;
  font-weight: 700;
  border-radius: 5px;
  transition: all 0.3s;
}
.cart__footer-accept:hover {
  background-color:transparent;
  transition: all 0.2s;
  border: 1px solid #ffb833;
  color: #ffb833;
  box-shadow: 0 0 10px 0 orange inset, 0 0 10px 4px orange;
}
.cart__footer-accept-disabled {
  background-color: #ffb833;
  height: 60px;
  width: 75%;
  font-weight: 700;
  border-radius: 5px;
}
.big-text {
  color: #ffb833;
  font-size: 2.5rem;
}
.lil-text {
  padding-right: 15px;
  padding-left: 10px;
  color: white;
  font-size: 1.5rem;
}
.order-link {
  width: 75%;
}



@media (max-width: 700px) {
  .cart__item {
    display: flex;
    padding: 10px 10px;
    /* flex-wrap: wrap; */
  }
  .cartItem__img-container {
    flex: 0 0 85px;
  }
  .cartItem__info {
    flex: 1 1 auto;
    display: flex;
    justify-content:space-between;
    flex-wrap: wrap;
  }

  .cartItem__name {
    width: 100%;
    text-align: left;
    /* white-space: nowrap; */
    flex: 0 1 100%;
    text-align: start;
  }
  .cartItem__weight{
    white-space: nowrap;
    flex: 0 1 100%;
    text-align: left;
    padding-bottom: 10px;
  }
  .cartItem__price{
    flex: 0 1 auto;
    white-space: nowrap;
  }
  .cartItem__buttons {
    white-space: nowrap;
    flex: 0 1 auto;
  }
  .cartItem__delete {
    flex: 0 1 auto;
  }

  .cartItem__delete-button {
    margin: 0 0 0 5px;
  }

  .cartItem__name h5,  .cartItem__weight h5{
    margin-left: 10px;
  }

  .cart__footer {
    flex-direction: column;
  }
  .cart__footer-block {
    padding: 10px 0;
  }
  .cart__footer-accept, .cart__footer-accept-disabled {
    margin-bottom: 20px;
  }
}


@media (max-width: 550px) {
.cart__close {
  position: absolute;
  right: 20px;
  top: 12px;
 }
}

@media (max-width: 425px) {
  .eclipce.active {
    display: none;
    /* top: 80px;
    transition: none; */
  }
  .cart__sidebar {
    top: 80px;
  }
  .cart__inner {
    height: calc(100% - 80px);
    min-height: calc(100% - 80px);
  }
  .cartItem__info {
    padding: 1px 1px;
  }
  .cartItem__price h5{
    font-size: 1rem;
  }
  .cartItem__name {
    white-space: wrap !important;
  }
  .cartItem__name h5{
    color: black;
    font-size: 1.3rem;
    margin-bottom: 3px;
  }
}

@media (max-width: 375px) {
  .cart__close {
    position: absolute;
    right: 12px;
    top: 12px;
   }
  .cartItem__name {
    order: 1;
  }
  .cartItem__weight{
    order: 2;
  }
  .cartItem__price{
    order: 3;
    flex: 0 1 100%;
    text-align: left;
    margin-left: 10px;
    padding-bottom: 10px;
  }
  .cartItem__buttons {
    order: 4;
  }
  .cartItem__delete {
    order: 5;
  }

  .cartItem__price h5 {
    font-size: 1.2rem;
  }
}