.navCart__button {
  background: none;
  border: none;
}

.navCart__text {
  width: 115px;
  color: #ffffff;
  text-decoration: none;
  line-height: 26px;
  font-size: 1.2rem;
  font-weight: 700;
  white-space: nowrap;
  margin-bottom: 0;
}

.bi-basket{
  color: orange;
  padding-right: 5px;
}
.active-basket {
  font-size: 1.5rem;
}


@media (max-width: 900px) {
  .navCart__text {
      margin-right: 30px;
    }
  }

@media (max-width: 600px) {
  .navCart__text {
    font-size: 1.5rem;
    margin-left: 20px;
    margin-right: 20px;
  }
  
}

@media (max-width: 425px) {
  .navCart__text {
    font-size: 1.2rem;
  }
}