/* for product */

.products__container {
  padding-left: 4vw;
  padding-right: 4vw;
  margin-top: 20px;
}

.product__card {
  margin-bottom: 15px;

  padding: 0 2vw;
  transition: 0.3s;
}

.product__card:hover {
  box-shadow: 0 15px 20px #6D6D6D;
  border-radius: 25px;
  transition: 0.3s;
}

.product__img-container {
  /* height: 250px; */
}

.product__img {
  width: 100%;
  height: 100%;
  margin-top: 15px;
  border-radius: 25px;
  object-fit:fill;
}

.product__header {
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-top: 20px;
  height: 45px;
}
.product__header > h6 {
  position: absolute;
  top: 25px;
  font-size: 13px;
}
.product__price {
  white-space: nowrap;
}

.product__about {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 15px;
  height: 48px;
}

.product__ingredients {
  -webkit-line-clamp: 2; 
  display: -webkit-box; 
  -webkit-box-orient: vertical; 
  overflow: hidden; 
}

.product__weight {
  white-space: nowrap;
  padding-left: 10px;
}

.product__buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.product__buttons-amount {
  display: flex;
  align-items: center;
}

.product__buttons-amount_btn {
  padding: 10px 15px;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  font-size: 1.25rem;
  text-transform: uppercase;
  white-space: nowrap;
  color: #3D3D3D;
  background-color: #F5F5F5;
  transition: 0.2s;
}

.product__buttons-amount_btn:hover {
  color: #fff;
  background-color: #ffb833;
  transition: 0.2s;
  box-shadow: 0 10px 15px #6D6D6D;
  color: white;
  transform: translateY(-1px);
}

.product__buttons-amount_number {
  padding: 0 1vw;
  font-weight: bold;
}

.product__buttons-order {
  padding: 10px 20px;
  border-radius: 8px;
  border: 0;
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: bold;
  color: #fff;
  background-color: #ffb833;
  border: 2px solid #ffb833;
  transition: 0.3s;
}
.product__buttons-order:hover {
 background-color: #fff;
 color: #ffb833;
 transition: 0.3s;
 box-shadow: 0 10px 15px #6D6D6D;
 transform: translateY(-1px);
}

.product__buttons-cancel {
  position: relative;
  padding: 10px 20px;
  border-radius: 8px;
  border: 0;
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: bold;
  color: #fff;
  background-color: rgba(252, 70, 4, 0.205);
  border: 2px solid rgba(252, 70, 4, 0.158);
  transition: 0.3s;
}

.product__buttons-cancel:hover {
  background-color: #fff;
  color: orangered;
  transition: 0.3s;
  box-shadow: 0 10px 15px #6D6D6D;
  transform: translateY(-1px);
 }

 .product__added {
  position: absolute;
  color: green;
  bottom: 47px;
  left: -2px;
  font-size: 12px;
  visibility: hidden;
 }

 .product__added.fadeOutDown{
  visibility: visible;
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
 }

 @-webkit-keyframes fadeOutDown {
  0% {
     opacity: 1;
     -webkit-transform: translateY(0);
  }
  100% {
     opacity: 0;
     -webkit-transform: translateY(15px);
  }
}

@keyframes fadeOutDown {
  0% {
     opacity: 1;
     transform: translateY(0);
  }
  100% {
     opacity: 0;
     transform: translateY(15px);
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

 @media (max-width: 1350px) {
  .product__card:hover {
    box-shadow: 0 7.5px 10px #6D6D6D;  }
 }

 @media (max-width: 575px) {
  .product__card:hover {
    box-shadow: none;
  }
  .product__buttons-amount_btn {
    padding: 10px 15px;
    margin-right: 10px;
  }
  .product__buttons-amount_number {
    margin-right: 10px;
  }
  .product__buttons-amount_btn:hover {
    box-shadow: 0 5px 7px #6D6D6D;
    transform: translateY(-1px);
  }
 }

 